@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
    @apply relative uppercase inline-block font-bold py-4 px-8 lg:py-7 lg:px-12 cursor-pointer no-underline border-4 font-headings transition-all;
}

.btn-small {
    @apply relative uppercase inline-block font-bold py-2 px-4 lg:py-3 lg:px-6 cursor-pointer no-underline border-4 font-headings transition-all;
}

.btn:hover,
.btn-small:hover {
    @apply bg-black text-white;
}

/* removing margin from colour background block when a colour block is added after */
.blocks div[class*='bg-']:not(.bg-white) + div[class*='bg-']:not(.bg-white) {
    margin-top: 0 !important;
}

@layer base {
    img {
        display: block;
        max-width: 100%;
        width: 100%;
        height: auto;
    }

    :root {
        @apply font-body;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply font-headings font-serif;
    }

    p {
        @apply font-sans;
    }

    .heading-h1 {
        @apply text-5xl md:text-7xl xl:text-9xl font-medium font-headings leading-none;
    }

    .heading-h2 {
        @apply text-4xl md:text-5xl lg:text-6xl xl:text-7xl 2xl:text-8xl font-medium font-headings leading-tight xl:leading-none;
    }

    .heading-h3 {
        @apply text-2xl md:text-4xl xl:text-6xl font-medium font-headings leading-tight xl:leading-none;
    }

    .heading-h4 {
        @apply text-xl md:text-2xl 2xl:text-3xl font-light font-headings leading-snug;
    }

    .heading-h5 {
        @apply text-sm md:text-lg 2xl:text-xl font-light font-headings leading-snug;
    }

    .paragraph {
        @apply text-base leading-normal font-body;
    }

    .paragraph-lg {
        @apply text-xl md:text-lg 2xl:text-xl leading-snug;
    }

    .paragraph-lg {
        @apply text-xl md:text-2xl 2xl:text-3xl leading-snug;
    }

    .heading-accordion {
        @apply text-sm md:text-base xl:text-lg font-light font-headings leading-snug;
    }
}

@layer components {
    .hero-title {
        @apply font-bold text-3xl py-12 md:text-4xl lg:text-5xl xl:text-6xl text-center text-white leading-7 md:leading-10;
    }

    .hero-text {
        @apply md:mt-8 mt-12 lg:w-10/12 font-normal text-center text-white text-xl;
    }

    .text-section {
        @apply text-off-white md:mt-5 mt-10 lg:w-10/12 font-normal text-base;
    }

    .input {
        @apply w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out;
    }

    label {
        @apply text-gray-600;
    }

    .embla {
        --slide-spacing: 1rem;
        --slide-size: 85%;
        --slide-height: 19rem;
        padding: 1.6rem;
    }

    .embla__container {
        backface-visibility: hidden;
        display: flex;
        touch-action: pan-y;
        margin-left: calc(var(--slide-spacing) * -1);
    }

    .embla__slide {
        flex: 0 0 var(--slide-size);
        min-width: 0;
        padding-left: var(--slide-spacing);
        position: relative;
    }

    .embla__slide__img {
        display: block;
        height: var(--slide-height);
        width: 100%;
        object-fit: cover;
    }
}
